<template>
  <div>
    <LinksLayout @layoutUpdated="setLayout" />
    <LinearLoader v-if="loadingNextPage" />
    <div class="my-4"></div>
    <div v-if="links.length > 0">
      <div class="link-area row p-2">
        <div
          v-for="link in allLinks"
          class="p-2 will-transition"
          :key="link._id"
          :class="[layout == 'grid' ? 'col-lg-4' : 'col-lg-6']"
        >
          <LinkCard
            :link="link"
            :extend="layout != 'grid'"
            @favouriteUpdated="handleFavouriteUpdated(link)"
          />
        </div>
      </div>
      <Pagination
        :pagination="pagination"
        :loadingNextPage="loadingNextPage"
        @nextPage="nextPage"
        @previousPage="previousPage"
        v-if="links.length > 0"
      />
    </div>
    <EmptyLink
      emptyMessage="You currently have no favourite link. Add one of your existing links to favourite or create a new one"
      v-if="!loadingNextPage && links.length == 0"
    />
  </div>
</template>
<script>
import axios from "axios";
import dayjs from "dayjs";
import LinkCard from "@/components/dash/LinkCard";
import LinksLayout from "@/components/dash/Links/LinksLayout";
import Pagination from "@/components/dash/Pagination";
import EmptyLink from "@/components/dash/Links/EmptyLink";
export default {
  components: {
    LinkCard,
    LinksLayout,
    Pagination,
    EmptyLink,
  },
  metaInfo: {
    title: "Your Links - Ana app",
  },
  name: "Links",
  data() {
    return {
      links: [],
      layout: "",
      linkCount: 0,
      isLoading: false,
      loadingNextPage: false,
      pagination: {
        page: 1,
        totalPages: 1,
        perPage: 12,
      },
    };
  },
  computed: {
    allLinks() {
      return this.links;
    },
  },
  async mounted() {
    await this.fetchLinks();
  },
  methods: {
    setLayout(layout) {
      this.layout = layout;
    },
    async fetchLinks() {
      this.loadingNextPage = true;
      try {
        const response = await axios.get("my-links", {
          params: {
            page: this.pagination.page,
            perPage: this.pagination.perPage,
            favourites: true,
          },
        });
        this.links = response.data.links;
        this.pagination = response.data.pagination;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "An Error Occured while fetching links. Please try again"
        );
      } finally {
        this.loadingNextPage = false;
      }
    },
    handleFavouriteUpdated(link) {
      if (!link.isFavorite) {
        const index = this.links.findIndex((l) => l._id === link._id);
        if (index !== -1) {
          this.links.splice(index, 1);
        }
      }
    },
    async nextPage() {
      if (this.pagination.page < this.pagination.totalPages) {
        try {
          this.pagination.page++;
          await this.fetchLinks();
          this.scrollToTop();
        } catch (error) {
          this.$toast.error("An error occurred. Please try again");
          this.pagination.page--;
        }
      }
    },
    async previousPage() {
      if (this.pagination.page > 1) {
        try {
          this.pagination.page--;
          await this.fetchLinks();
          this.scrollToTop();
        } catch (error) {
          this.pagination.page++;
          this.$toast.error("An error occurred. Please try again");
        }
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: 1px solid var(--anaGrey2);
  color: var(--primaryColor);
  text-transform: lowercase;
  font-variant: small-caps;
  font-size: 1rem;
  min-width: 120px !important;
  cursor: pointer;
}
.ana-tabs {
  border: unset;
}

.style-chooser .vs__dropdown-menu {
  margin-top: 1rem;
}

.vs__clear {
  display: none !important;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}

.ana-tabs .nav-link {
  color: #999;
  border: none;
}

.ana-tabs .nav-link.active {
  color: var(--primaryColor);
}
</style>
