<template>
  <div class="mb-4">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center ana-tabs">
          <router-link
            :to="item.path"
            class="ana-tabs__item"
            v-for="(item, index) in menuList"
            :key="index"
            >{{ item.name }}</router-link
          >
        </div>
      </div>

      <div class="d-none d-lg-flex align-items-center">
        <button
          @click="setLayout('list')"
          class="layout-toggle"
          :class="{ active: layout == 'list' }"
        >
          <i class="bi bi-layout-split"></i>
        </button>
        <span class="p-1"></span>
        <button
          @click="setLayout('grid')"
          class="layout-toggle"
          :class="{ active: layout == 'grid' }"
        >
          <i class="bi bi-grid-3x3"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinksLayout",
  data() {
    return {
      menuList: [
        {
          name: "All",
          path: "/dash/YourLinks",
        },
        {
          name: "Favourites",
          path: "/dash/YourLinks/Favourites",
        },
      ],
      layout: "",
    };
  },
  mounted() {
    this.setLayout("grid");
  },
  methods: {
    setLayout(layout) {
      this.layout = layout;
      this.$emit("layoutUpdated", layout);
    },
  },
};
</script>

<style></style>
