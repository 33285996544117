<template>
  <div class="ana-pagination mt-4">
    <button
      class="ana-pagination__button"
      @click="previousPage"
      :disabled="pagination.page === 1 || loadingNextPage"
    >
      <i class="bi bi-chevron-double-left"></i>
    </button>
    <div class="ana-pagination__page-info">
      {{ pagination.page }} of {{ pagination.totalPages }}
    </div>
    <button
      class="ana-pagination__button"
      @click="nextPage"
      :disabled="pagination.page === pagination.totalPages || loadingNextPage"
    >
      <i class="bi bi-chevron-double-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    loadingNextPage: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    nextPage() {
      if (this.pagination.page < this.pagination.totalPages) {
        this.$emit("nextPage");
      }
    },
    previousPage() {
      if (this.pagination.page > 1) {
        this.$emit("previousPage");
      }
    },
  },
};
</script>
